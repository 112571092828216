var moment = require("moment-timezone");
var _ = require("lodash");
var sanitizeCustomFields = function(customFields, fieldList) {
  var modCustomFields = {};

  //Loop through the data and if date is found, suffix with -DT and format the value
  Object.entries(customFields).forEach(([key, value]) => {
    var fieldDef = _.find(fieldList, (f) => {
      return f.name == key;
    });

    if (!fieldDef) return value;
    //format("YYYY-MM-DD[T00:00:00.000Z]")
    switch (fieldDef.type) {
      case "date":
        if (value && value.toString() != "" && moment(value).isValid()) {
          value = moment(value).format();
          key = key + "-DT"; //Suffixing with Date
        } else {
          value = "";
        }

        break;
      case "text":
        if (value) value = value.toString().trim();
        else value = "";
        break;
      case "number":
        if (value) value = Number(value);
        else value = 0;
        break;
      case "mcq":
        if (!_.includes(fieldDef.mcqOptions, value)) value = "";
        break;
      case "checkbox":
        var valueArray = [];
        if (typeof value != "undefined") {
          value = value.toString();
          var values = value.split(/[ ,]+/);

          for (var i in values) {
            var v = values[i];
            if (!_.includes(fieldDef.checkboxOptions, v.toUpperCase()))
              continue;
            else valueArray.push(v.toUpperCase());
          }
          value = valueArray;
        } else {
          value = "";
        }
    }

    modCustomFields[key] = value;
  });

  return modCustomFields;
};
module.exports = {
  sanitizeCustomFields,
};
