<template>
  <v-container>
    <!-- <Breadcrumbs /> -->
    <v-layout row wrap class="ma-0">
      <v-flex xs12 class="ma-md-10">
        <v-card class="py-5 mx-md-15 rounded">
          <v-card-title>
            <v-row style="margin:0px !important">
              <v-flex xs12 sm6 offset-sm3 md6 offset-md3>
                <h2
                  style="font-size: 24px; font-weight: 500; letter-spacing: 0.5px; opacity: 0.9"
                >
                  Google Sheet Import
                </h2>
              </v-flex>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row style="margin:0px !important">
              <v-flex xs12 sm6 offset-sm3 md6 offset-md3>
                <div>
                  <h3
                    style="font-size: 16px; font-weight: 500; opacity: 0.9; margin-bottom: 20px; margin-top: -10px"
                  >
                    GoDial allows you to import a Google sheet into a list
                    easily. Here is how it works
                  </h3>
                </div>
              </v-flex>
              <v-flex xs12 sm6 offset-sm3 md6 offset-md3>
                <div>
                  <p>1. Click Start</p>
                  <p>2. Choose A Google sheet</p>
                  <p>3. Choose List to import into</p>
                  <p>4. Map the columns from your sheets with GoDial fields</p>
                </div>
              </v-flex>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row style="margin: 0px">
              <v-flex
                xs12
                sm4
                offset-sm4
                md4
                offset-md4
                style="text-align: center !important"
              >
                <v-btn
                  block
                  color="primarygrad white--text"
                  @click="openContactPicker"
                >
                  Start
                </v-btn>
              </v-flex>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <contactImport
      ref="contactImport"
      :lists="lists"
      :fieldlist="fieldList"
      :selectedlist="''"
    ></contactImport>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import contactImport from "../components/contactImport.vue";

import { mapGetters } from "vuex";
const _ = require("lodash");

export default {
  components: {
    contactImport,
  },
  data() {
    return {
      lists: [],
      loader: false,
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "teams", "selectedTeam", "fieldList"]),
  },
  watch: {
    async selectedTeam() {
      try {
        await this.fetchLists(); // fetch list
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    await this.fetchLists();
  },

  methods: {
    // Open contact Picker
    openContactPicker() {
      this.$refs.contactImport.open("gSheet");
    },

    /** recieve teams array of ids */
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },

    // Fetch all the lists
    async fetchLists() {
      try {
        this.loader = true;
        this.lists = [];
        // Teams sections
        var teamIds = this.getTeamIds();

        var res = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = res.body;
        this.loader = false;
      } catch (e) {
        console.log(e);
        this.loader = false;
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 4px;
}
</style>
