<template>
  <div>
    <v-dialog v-model="importDialog" max-width="850" persistent>
      <v-card elevation="0">
        <v-card-title class="primarygrad">
          Import
          <v-spacer></v-spacer>
          <v-icon @click="close()">mdi-close-circle</v-icon>
        </v-card-title>

        <v-card-text class="pa-0 ma-0">
          <v-stepper v-model="step" tile flat>
            <v-stepper-header class="grey darken-3 elevation-0 my-n2">
              <v-stepper-step :complete="step > 1" step="1">
                <b class="white--text">Select File</b>
              </v-stepper-step>
              <v-divider class="primary"></v-divider>
              <v-stepper-step :complete="step > 2" step="2">
                <b class="white--text">Configure Import</b>
              </v-stepper-step>
              <v-divider class="primary"></v-divider>
              <v-stepper-step step="3">
                <b class="white--text">Result</b>
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1" class="pa-0 ma-0">
                <v-card flat tile>
                  <div v-if="page == 'list'">
                    <v-card-title
                      >Data Source
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        depressed
                        class="primarygrad"
                        @click="fileRequirements"
                        >File Requirements</v-btn
                      >
                    </v-card-title>
                    <v-card-text>
                      <v-row class="pa-0 ma-0">
                        <v-col cols="12" class="pa-0  droppercard">
                          <v-card
                            flat
                            :class="{ 'grey lighten-2': dragover }"
                            style="  border: none;"
                            @drop.prevent="onDrop($event)"
                            @dragover.prevent="dragover = true"
                            @dragenter.prevent="dragover = true"
                            @dragleave.prevent="dragover = false"
                          >
                            <v-btn
                              icon
                              style="position: absolute;
    right: 5px;"
                              @click="clearDialog"
                            >
                              <v-icon id="close-button">mdi-close</v-icon>
                            </v-btn>
                            <input
                              ref="uploader"
                              type="file"
                              style="display: none"
                              @click="resetFileChanged"
                              @change="onFileChanged"
                            />
                            <v-card-text @click="uploadCsv()">
                              <v-row
                                class="d-flex flex-column"
                                dense
                                align="center"
                                justify="center"
                              >
                                <v-icon
                                  :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
                                  size="60"
                                >
                                  mdi-cloud-upload
                                </v-icon>
                                <p
                                  v-if="fileName === ''"
                                  style="text-align:center"
                                >
                                  Choose a .xls or .csv file with contact data
                                  and drop it here
                                  <br />
                                  or click to select them.
                                </p>
                                <p v-else>
                                  {{ fileName }}
                                </p>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <h3
                          v-if="fileInput"
                          class="text-center"
                          style=" font-size: 14px; font-weight: 500; opacity: 0.9;margin:auto"
                        >
                          Just One More Step!! your file is ready to import
                        </h3>
                        <v-col cols="12" class="pl-8 d-flex">
                          <div>
                            Foreign Language File?
                            <v-tooltip top max-width="300">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  class="ml-2"
                                  color="amber"
                                  v-bind="attrs"
                                  v-on="on"
                                  >info_outline</v-icon
                                >
                              </template>
                              <span
                                >If your CSV file cannot be read by GoDial, you
                                can open the file in notepad and copy its
                                content and paste it here</span
                              >
                            </v-tooltip>
                          </div>
                          <div style="padding-left: 30px;">
                            <v-btn
                              plain
                              small
                              depressed
                              :dark="pasteCSV"
                              :outlined="!pasteCSV"
                              :fill="pasteCSV"
                              @click="pasteCSV = !pasteCSV"
                            >
                              Paste CSV
                            </v-btn>
                          </div>
                          <!-- <p>Foreign Language File?</p> 
                          <v-spacer></v-spacer>
                          <v-btn
                            small
                            depressed
                            :dark="pasteCSV"
                            :outlined="!pasteCSV"
                            :fill="pasteCSV"
                            color="primarygrad"
                            @click="pasteCSV = !pasteCSV"
                          >
                            Paste CSV
                          </v-btn> -->
                        </v-col>
                        <v-col v-if="pasteCSV" cols="12">
                          <v-textarea
                            v-model="csvPastedData"
                            outlined
                            label="Paste Data Here"
                            value
                            @change="paste"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                  <div v-else>
                    <v-card-title style="padding-bottom: 0px"
                      >Data Source</v-card-title
                    >
                    <v-card-text style="text-align: center !important">
                      <div align="center">
                        <v-img
                          :src="fileInputPic"
                          style="
                            width: 130px;
                            justify-content: center;
                            opacity: 0.6;
                          "
                        ></v-img>
                      </div>

                      <v-flex
                        xs12
                        sm12
                        md12
                        style="text-align: center !important"
                      >
                        <v-btn
                          filled
                          color="primarygrad white--text"
                          dense
                          @click="driveIconClicked"
                          >Choose Google Sheet</v-btn
                        >
                        <div v-if="fileInput" style="padding: 5px">
                          <h3
                            style="
                              font-size: 14px;
                              font-weight: 500;
                              opacity: 0.9;
                            "
                          >
                            Hooray!! your file is ready to import
                          </h3>
                        </div>
                      </v-flex>
                    </v-card-text>
                  </div>

                  <v-card-title class="mt-n5">
                    Import to List
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="ml-2"
                          color="amber"
                          v-bind="attrs"
                          v-on="on"
                          >info_outline</v-icon
                        >
                      </template>
                      <span>
                        Choose a list where imported data will be uploaded
                      </span>
                    </v-tooltip>
                  </v-card-title>

                  <v-card-text class="py-0">
                    <v-row v-if="!selectListFromCSV" class="pa-0 ma-0">
                      <v-col cols="12" class="pa-0 ma-0">
                        <v-select
                          v-model="selectedlist"
                          :items="
                            lists.filter((l) => {
                              return l.id != 'allLists';
                            })
                          "
                          item-text="name"
                          item-value="id"
                          label="Choose List"
                          solo
                          hide-details
                          background-color="inverted"
                          flat
                        />
                      </v-col>
                    </v-row>

                    <v-card-title class="px-0 mx-0 mb-1 mt-n5">
                      Advance
                    </v-card-title>

                    <v-row class="px-0 mx-0 mb-1">
                      <v-col cols="12" md="5">
                        <v-expansion-panels accordion flat>
                          <v-expansion-panel>
                            <v-expansion-panel-header class="inverted"
                              ><span style="text-align:center">
                                <v-icon class="ml-2">mdi-table-cog</v-icon>
                              </span>
                              <span style="text-align:left"> Settings</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row style="margin: 0px" justify="center">
                                <v-flex xs12 sm12 md12>
                                  <v-checkbox
                                    v-model="selectListFromCSV"
                                    color="primarygrad"
                                  >
                                    <template v-slot:label>
                                      My data has the name of the list

                                      <v-tooltip top max-width="300">
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            small
                                            class="ml-2"
                                            color="amber"
                                            v-bind="attrs"
                                            v-on="on"
                                            >info_outline</v-icon
                                          >
                                        </template>
                                        <span
                                          >You can import a single file with
                                          multiple contacts each belonging to
                                          different lists by specifying the name
                                          of the list on a column</span
                                        >
                                      </v-tooltip>
                                    </template>
                                  </v-checkbox>
                                </v-flex>

                                <v-flex v-if="selectListFromCSV" xs12 sm12 md12>
                                  <v-select
                                    v-model="importedItem.list"
                                    :items="headerList"
                                    label="In which column?"
                                    outlined
                                    dense
                                  ></v-select>
                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                  <v-checkbox
                                    v-model="selectAssigneeFromCSV"
                                    style="margin-top: -3px"
                                    color="primarygrad"
                                  >
                                    <template v-slot:label>
                                      My data has the username of the Assignee

                                      <v-tooltip top max-width="300">
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            small
                                            class="ml-2"
                                            color="amber"
                                            v-bind="attrs"
                                            v-on="on"
                                            >info_outline</v-icon
                                          >
                                        </template>
                                        <span
                                          >You can import a file where each
                                          contact is pre assigned to a member,
                                          you can pass his username in a
                                          column</span
                                        >
                                      </v-tooltip>
                                    </template>
                                  </v-checkbox>
                                </v-flex>

                                <v-flex
                                  v-if="selectAssigneeFromCSV"
                                  xs12
                                  sm12
                                  md12
                                >
                                  <v-select
                                    v-model="importedItem.assignee"
                                    :items="headerList"
                                    label="In which column?"
                                    outlined
                                    dense
                                  ></v-select>
                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                  <v-checkbox
                                    v-model="addCountryCode"
                                    style="margin-top: -3px"
                                    color="primarygrad"
                                  >
                                    <template v-slot:label>
                                      I want to specify country code?

                                      <v-tooltip top max-width="300">
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            small
                                            class="ml-2"
                                            color="amber"
                                            v-bind="attrs"
                                            v-on="on"
                                            >info_outline</v-icon
                                          >
                                        </template>
                                        <span
                                          >You can import a file where each
                                          contact is pre assigned to a member,
                                          you can pass his username in a
                                          column</span
                                        >
                                      </v-tooltip>
                                    </template>
                                  </v-checkbox>
                                </v-flex>
                                <v-flex v-if="addCountryCode" xs12 sm12 md12>
                                  <v-text-field
                                    v-model="countryCode"
                                    outlined
                                    dense
                                    label="Country Code (ex - +91)"
                                  ></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                  <v-checkbox
                                    v-model="orderedImport"
                                    color="primarygrad"
                                  >
                                    <template v-slot:label>
                                      Maintain the same sequence/order as my
                                      file when importing

                                      <v-tooltip top max-width="300">
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            small
                                            class="ml-2"
                                            color="amber"
                                            v-bind="attrs"
                                            v-on="on"
                                            >info_outline</v-icon
                                          >
                                        </template>
                                        <span
                                          >Import contacts in the same order as
                                          you files. Note: Max 7500 contacts can
                                          be imported at once in order.</span
                                        >
                                      </v-tooltip>
                                    </template>
                                  </v-checkbox>
                                </v-flex>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col
                        v-if="!selectAssigneeFromCSV"
                        cols="12"
                        md="7"
                        style="padding-right:0"
                      >
                        <v-expansion-panels accordion flat>
                          <v-expansion-panel>
                            <v-expansion-panel-header class="inverted">
                              <span style="text-align:center">
                                <v-icon class="ml-2">mdi-head-lightbulb</v-icon>
                              </span>
                              <span style="text-align:left"
                                >Assignment Logic</span
                              >
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row style="margin: 0px" justify="center">
                                <v-radio-group
                                  v-model="addAgent"
                                  @change="selectAllUsers()"
                                >
                                  <v-flex xs12 sm12 md12>
                                    <v-radio
                                      value="addAgentDefault"
                                      label="First Come First Serve (default)"
                                    ></v-radio>
                                  </v-flex>
                                  <v-flex xs12 sm12 md12>
                                    <v-radio
                                      value="addAgentList"
                                      label="Equally Split between agent names currently assigned to the list"
                                    ></v-radio>
                                  </v-flex>
                                  <v-col
                                    v-if="addAgent === 'addAgentList'"
                                    cols="12"
                                    sm="12"
                                  >
                                    <v-combobox
                                      v-model="selectAssignedUser"
                                      :items="selectUsersForAssignment"
                                      item-text="name"
                                      item-value="id"
                                      label="Select Members"
                                      class="mb-1 mx-1"
                                      autocomplete="false"
                                      :allow-overflow="false"
                                      multiple
                                      clearable
                                      chips
                                      dense
                                      filled
                                      solo-inverted
                                      flat
                                      small-chips
                                    >
                                      <template
                                        v-slot:selection="{
                                          attrs,
                                          item,
                                          select,
                                          selected,
                                        }"
                                      >
                                        <v-chip
                                          small
                                          label
                                          dark
                                          class="mb-1 mr-1"
                                          v-bind="attrs"
                                          :input-value="selected"
                                          @click="select"
                                        >
                                          {{ item.name }}
                                        </v-chip>
                                      </template>
                                    </v-combobox>
                                  </v-col>
                                </v-radio-group>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2" class="px-0">
                <v-card flat color="transparent">
                  <div class="px-3 text-center">
                    <h2 class="mb-1">Configure Import</h2>
                    <span class="grey--text"
                      >Map each columns from your spreadsheeet to the GoDial's
                      fields, Once the configuration is set, click import to
                      start importing the data</span
                    >
                  </div>

                  <v-card outlined class="scroller ma-3 pa-3" height="300">
                    <v-row>
                      <v-col cols sm="4">
                        <h4 class="pt-2">Name</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem.name"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols sm="4">
                        <h4 class="pt-2">Primary Phone</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem.phone"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols sm="4">
                        <h4 class="pt-2">Second Phone</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem.secondPhone"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols sm="4">
                        <h4 class="pt-2">Email</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem.email"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols sm="4">
                        <h4 class="pt-2">Company</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem.companyName"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols sm="4">
                        <h4 class="pt-2">Address</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem.address"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols sm="4">
                        <h4 class="pt-2">Extra</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem.extra"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols sm="4">
                        <h4 class="pt-2">Remarks</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem.remarks"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols sm="4">
                        <h4 class="pt-2">Note</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem.note"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row v-for="(field, i) in fieldlist" :key="i">
                      <v-col cols sm="4">
                        <h4 class="pt-2">{{ field.name }}</h4>
                      </v-col>
                      <v-col cols sm="4">
                        <h3 class="pt-2 font-weight-medium">
                          <i> maps to Column</i>
                        </h3>
                      </v-col>
                      <v-col cols sm="4">
                        <v-select
                          v-model="importedItem['customFields'][field.name]"
                          :items="headerList"
                          outlined
                          hide-details
                          dense
                        />
                      </v-col>
                    </v-row>

                    <div align="center">
                      <v-btn depressed color="grey white--text" to="/fields">
                        <v-icon color="white" style="margin-bottom: 0px;"
                          >add_circle_outline</v-icon
                        >
                        Add Custom Fields</v-btn
                      >
                    </div>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3" class="px-0">
                <v-card flat color="transparent" class="px-2">
                  <v-card-title>
                    <div>
                      <h3 style="font-size: 16px; opacity: 0.7">
                        Successful: {{ numberOfItems }}
                      </h3>
                      <h3 style="font-size: 16px; opacity: 0.7">
                        Error: {{ Object.keys(response).length }}
                      </h3>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      small
                      color="primarygrad"
                      @click="downloadResults"
                    >
                      Download Report in Details</v-btn
                    >
                  </v-card-title>
                  <v-card-text
                    v-if="contactImportResults"
                    style="height:300px;overflow-y:scroll"
                  >
                    <v-row class="pa-0 ma-0">
                      <v-col class="pa-1 ma-0">
                        <h3>Name</h3>
                      </v-col>
                      <v-col class="pa-1 ma-0">
                        <h3>Primary Number</h3>
                      </v-col>
                      <v-col class="pa-1 ma-0">
                        <h3>Result</h3>
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="(result, idx) in payloadContact"
                      :key="idx"
                      class="pa-0 ma-0"
                    >
                      <!-- <v-col class="pa-1 ma-0">
                        <v-card class="pa-1 fill-height text-end" flat outlined>{{idx}}</v-card>
                      </v-col> -->
                      <v-col class="pa-1 ma-0">
                        <v-card class="pa-1 fill-height " flat outlined>{{
                          result.name
                        }}</v-card>
                      </v-col>
                      <v-col class="pa-1 ma-0">
                        <v-card class="pa-1 fill-height " flat outlined>{{
                          result.phone
                        }}</v-card>
                      </v-col>
                      <v-col class="pa-1 ma-0">
                        <v-card
                          :class="
                            result.result == 'Successfully Imported'
                              ? 'light-green lighten-3'
                              : 'red lighten-4'
                          "
                          class="pa-1 fill-height "
                          flat
                          outlined
                          >{{ result.result }}</v-card
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <!-- <v-subheader
                    v-if="Object.keys(response).length > 0"
                    style="margin-left: 9px"
                  >
                    Errors
                  </v-subheader> -->
                  <!-- <v-card-text
                    v-if="Object.keys(response).length > 0"
                    style="height: 400px"
                  >
                    <v-list>
                      <v-list-item-group color="primarygrad">
                        <v-list-item v-for="(item, i) in response" :key="i">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.for
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.msg
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>

                  <v-card-text v-else style="height: 400px">
                    <v-container
                      fill-height
                      fill-width
                      style="text-align: center; width: 100%; display: inline"
                    >
                      <div style="text-align: center" align="center">
                        <div align="center">
                          <v-img
                            :src="importSuccessfully"
                            style="width: 200px; justify-content: center"
                          ></v-img>
                        </div>
                        <br />
                        <h3>All contacts added successfully</h3>
                      </div>
                    </v-container>
                  </v-card-text> -->
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-card-actions>
          <v-btn v-if="step == 2" depressed text @click="step--">Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="step == 1"
            depressed
            color="primarygrad white--text"
            @click="next"
            >Next</v-btn
          >

          <v-btn
            v-else-if="step == 2"
            depressed
            :disabled="importing"
            color="primarygrad white--text"
            @click="importContacts"
          >
            <v-progress-circular
              v-if="importing"
              indeterminate
              class="mr-2"
              color="white"
            ></v-progress-circular>
            Import
          </v-btn>

          <v-btn v-if="step == 3" depressed dark @click="close()">CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileRequirementDialog" persistent width="500">
      <v-card>
        <v-card-title class="primarygrad white--text"
          >File Requirements</v-card-title
        >
        <v-card-text style="padding: 15px">
          <v-row style="margin: 0px">
            <div style="font-size: 16px;color: black;">
              Before exporting kindly make sure of the following:-
            </div>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-btn fab small depressed color="primary">
                    1
                  </v-btn>
                </v-list-item-action>

                <v-list-item-content style="font-size: 16px;color: black;">
                  Your excel file should only have one-sheet.
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-btn fab small depressed color="primary">
                    2
                  </v-btn>
                </v-list-item-action>

                <v-list-item-content style="font-size: 16px;color: black;">
                  Every column should have a name.
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeFileReq">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- snakbar for displaying messages -->
    <v-snackbar v-model="snackbar" :color="color" multi-line>
      {{ responseText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="indigo"
          text
          v-bind="attrs"
          @click.native="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import * as XLSX from "xlsx/xlsx.mjs";
import validator from "../js/validator.js";
import Papa from "papaparse";
import Blob from "blob";
import fb from "../js/firebase";
import { sanitizeCustomFields } from "../js/util.js";
const _ = require("lodash");
const { GoogleSpreadsheet } = require("google-spreadsheet");

export default {
  props: ["lists", "fieldlist", "selectedlist"],
  data() {
    return {
      fileRequirementDialog: false,
      addAgent: "addAgentDefault",
      selectUsersForAssignment: [],
      selectAssignedUser: [],
      assignedUsers: [],
      snackbar: false,
      responseText: "",
      color: "success",
      // Bulk data
      orderedImport: false,
      numberOfItems: 0,
      fileInput: false,
      fileName: "",
      importedData: [],
      step: 1,
      pasteCSV: false,
      csvPastedData: "",
      selectListFromCSV: false,
      selectAssigneeFromCSV: false,
      importing: false,
      importedItem: {
        name: "",
        phone: "",
        secondPhone: "",
        email: "",
        companyName: "",
        assignee: "",
        address: "",
        extra: "",
        remarks: "",
        list: "",
        note: "",
        customFields: {},
      },
      addCountryCode: false,
      countryCode: "",
      loader: false,
      headerList: [],
      response: {},
      sheetId: "",
      sheetName: "",
      pickerApiLoaded: false,
      developerKey: "AIzaSyDyvsAP4DI31z9TtRSrS4YtJslREfOoI40",
      clientId:
        "733503495552-n4b2kd4toqmc6ligf0dj01qo7piegl48.apps.googleusercontent.com",
      //scope:
      //"https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.file",
      scope: [
        "https://www.googleapis.com/auth/drive.file",
        "https://www.googleapis.com/auth/spreadsheets.readonly",
      ],
      DISCOVERY_DOCS: [
        "https://sheets.googleapis.com/$discovery/rest?version=v4",
      ],
      oauthToken: null,
      appId: "733503495552",
      importDialog: false,
      page: "",
      contactImportResults: [],
      dragover: false,
      uploadedFiles: [],
      payloadContact: [],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT"]),
    importSuccessfully() {
      return require("../assets/importSuccessfully.png");
    },
    fileInputPic() {
      return require("../assets/fileinput.png");
    },
  },
  created() {
    try {
      this.clearImportedItem();
      this.clearEditedItem();
      var gDrive = document.createElement("script");
      gDrive.setAttribute("type", "text/javascript");
      gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
      document.head.appendChild(gDrive);
      var papaParse = document.createElement("script");
      papaParse.setAttribute(
        "src",
        "https://cdnjs.cloudflare.com/ajax/libs/PapaParse/5.3.0/papaparse.min.js"
      );
      papaParse.setAttribute(
        "integrity",
        "sha512-rKFvwjvE4liWPlFnvH4ZhRDfNZ9FOpdkD/BU5gAIA3VS3vOQrQ5BjKgbO3kxebKhHdHcNUHLqxQYSoxee9UwgA=="
      );
      papaParse.setAttribute("crossorigin", "anonymous");
      document.head.appendChild(papaParse);
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    fileRequirements() {
      this.fileRequirementDialog = true;
    },
    closeFileReq() {
      this.fileRequirementDialog = false;
    },
    open(page) {
      try {
        // init field list
        this.clearImportedItem();
        // selected list
        if (this.selectedlist == "") {
          this.selectedlist =
            this.lists.length > 0 && !this.selectedlist ? this.lists[0].id : "";
        }
        var id = this.selectedlist;
        this.preloader = true;
        this.assignedUsers = [];
        this.selectUsersForAssignment = [];
        if (typeof id === "undefined" || id == undefined || id == "") {
          return; //return if id doesnt exist
        }

        this.$http
          .get(`${this.ENDPOINT}/lists/${this.selectedlist}/accounts`)
          .then((response) => {
            this.preloader = false;
            this.selectUsersForAssignment = response.body;
            // console.log("this.assignedusers", this.selectUsersForAssignment);
          })
          .catch((e) => {
            this.loader = false;
            this.preloader = false;
            this.$swal({ type: "error", text: "Error while getting accounts" });
          });

        this.page = page;
        this.importDialog = true;
      } catch (e) {
        console.log("error in open", e);
      }
    },

    async driveIconClicked() {
      const self = this;
      gapi.load("auth", { callback: onAuthApiLoad });
      gapi.load("picker", { callback: onPickerApiLoad });
      gapi.load("client:auth2");

      function onAuthApiLoad() {
        window.gapi.auth.authorize(
          {
            client_id: self.clientId,
            scope: self.scope,
            immediate: false,
            discovery_docs: self.DISCOVERY_DOCS,
          },
          handleAuthResult
        );
      }

      function onPickerApiLoad() {
        self.pickerApiLoaded = true;
        createPicker();
      }

      function handleAuthResult(authResult) {
        if (authResult && !authResult.error) {
          self.oauthToken = authResult.access_token;
          createPicker();
        }
      }

      // Create and render a Picker object for searching images.
      function createPicker() {
        if (self.pickerApiLoaded && self.oauthToken) {
          var view = new google.picker.View(google.picker.ViewId.SPREADSHEETS);
          view.setMimeTypes("application/vnd.google-apps.spreadsheet");
          var picker = new google.picker.PickerBuilder()
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .setAppId(self.appId)
            .setOAuthToken(self.oauthToken)
            .addView(view)
            .addView(new google.picker.DocsUploadView())
            .setDeveloperKey(self.developerKey)
            .setCallback(pickerCallback)
            .build();
          picker.setVisible(true);
        }
      }

      // A simple callback implementation.
      function pickerCallback(data) {
        if (data.action == google.picker.Action.PICKED) {
          var fileId = data.docs[0].id;

          self.loadSheet(fileId);
        }
      }
    },

    createPayload(ctx) {
      const self = this;
      try {
        self.importedData = [];
        self.headerList = [];
        // define headerList
        self.headerList = ctx[0];
        self.headerList.push("None");
        for (var user in this.selectUsersForAssignment) {
          // console.log("user", this.selectUsersForAssignment[user].role);
          if (this.selectUsersForAssignment[user].role === "Agent")
            this.assignedUsers.push(this.selectUsersForAssignment[user]);
        }

        self.headerList = _.map(self.headerList, (d) => {
          if (d == "" || d == undefined) {
            d = "Blank";
          }
          return d;
        });

        for (var y in ctx) {
          var payload = {};
          if (y == 0) {
            continue;
          }

          for (var x in self.headerList) {
            payload[self.headerList[x]] = ctx[y][x];
          }

          self.importedData.push(payload);
        }

        return true;
      } catch (e) {
        console.log(e);
      }
    },
    loadSheet(fileId) {
      const self = this;
      self.step = 1;

      initClient();

      function initClient() {
        // Listen for sign-in state changes.
        window.gapi.client.load("drive", "v2", getSpreadsheet);
      }

      function getSpreadsheet(e) {
        var spreadsheet;

        window.gapi.client.drive.files
          .export({
            fileId: fileId,
            fields:
              "appProperties,capabilities,contentHints,createdTime,description,explicitlyTrashed,fileExtension,folderColorRgb,fullFileExtension,headRevisionId,iconLink,id,imageMediaMetadata,isAppAuthorized,kind,lastModifyingUser,md5Checksum,mimeType,modifiedByMeTime,modifiedTime,name,originalFilename,ownedByMe,owners,parents,permissions,properties,quotaBytesUsed,shared,sharedWithMeTime,sharingUser,size,spaces,starred,thumbnailLink,trashed,version,videoMediaMetadata,viewedByMe,viewedByMeTime,viewersCanCopyContent,webContentLink,webViewLink,writersCanShare",
            mimeType: "text/csv",
          })
          .then(async function(response) {
            spreadsheet = Papa.parse(response.body);
            var isCreated = await self.createPayload(spreadsheet.data);
            if (!isCreated) {
              // error while creating imported data
              return;
            }
            self.fileInput = true;
            self.clearImportedItem({
              phone: self.guess("phone", self.headerList),
              secondPhone: self.guess("second", self.headerList),
              name: self.guess("name", self.headerList),
              companyName: self.guess("company", self.headerList),
              remarks: self.guess("remarks", self.headerList),
              address: self.guess("address", self.headerList),
              email: self.guess("email", self.headerList),
              extra: self.guess("extra", self.headerList),
              note: self.guess("note", self.headerList),
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    clearEditedItem() {
      // this.$emit("clearEditedData");
    },

    clearImportedItem(payload) {
      // this.$refs.chooseFileAttach.value = null;
      this.importedItem = {
        name: "",
        phone: "",
        secondPhone: "",
        companyName: "",
        address: "",
        email: "",
        extra: "",
        remarks: "",
        note: "",
        customFields: {},
        modifiedOn: new Date(),
      };

      var i = 0;
      _.each(this.fieldlist, (l) => {
        this.importedItem.customFields[l.name] = "";
        i++;
      });

      if (payload) this.importedItem = _.merge(this.importedItem, payload);
    },
    next() {
      if (this.step == 1) {
        // file input
        if (!this.fileInput) {
          // upload a file to continue
          this.snackbar = true;
          this.color = "#D18700";
          this.responseText = "Upload a file to continue";
          return;
        }

        // list seelction
        if (this.selectedlist == "") {
          //error list should be selected
          this.snackbar = true;
          this.color = "#D18700";
          this.responseText = "Error list should be selected";
          return;
        }

        // country code
        if (this.addCountryCode && this.countryCode == "") {
          // error country is not specified
          this.snackbar = true;
          this.color = "#D18700";
          this.responseText = "Error country is not specified";
          return;
        }

        // specify list
        if (this.selectListFromCSV) {
          if (
            this.importedItem.list == "" ||
            this.importedItem.list == "None"
          ) {
            // list specify filed is not defined
            this.snackbar = true;
            this.color = "#D18700";
            this.responseText = "List specify filed is not defined";
            return;
          }
        }
      }
      this.step++;
      return;
    },
    upload(ev) {
      const self = this;

      // self.loader = true
      self.fileInput = false;
      self.headerList = []; // init headerList as empty array
      self.importedData = []; // init the data as empty array
      // init the file after load
      var file = "";

      try {
        file = ev;
        var allowedFormats = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "text/csv",
          "text/comma-separated-values",
        ];

        if (file.type == "" || !allowedFormats.includes(file.type)) {
          // self.loader = false;
          self.snackbar = true;
          self.color = "#D18700";
          self.responseText =
            "Please select a csv or excel file. Incase your file is not supported, paste your csv data below.";

          return;
        }

        self.fileInput = true;
        self.fileName = ev.name;

        const reader = new FileReader();
        reader.onload = async function(eve) {
          var data = XLSX.read(eve.target.result, {
            type: "binary",
            cellDates: true,
          });
          var XL_row_object = [];

          var sheetName = data.SheetNames[0];
          var sheet = data.Sheets[data.SheetNames[0]];

          XL_row_object = XLSX.utils.sheet_to_json(sheet, {
            raw: true,
            header: 1,
            blankrows: false,
          });

          await self.createPayload(XL_row_object);
          //Lets guess the file

          var payload = {
            phone: self.guess("phone", self.headerList),
            secondPhone: self.guess("second", self.headerList),
            name: self.guess("name", self.headerList),
            companyName: self.guess("company", self.headerList),
            remarks: self.guess("remarks", self.headerList),
            address: self.guess("address", self.headerList),
            note: self.guess("note", self.headerList),
            email: self.guess("email", self.headerList),
            extra: self.guess("extra", self.headerList),
          };
          payload.customFields = {};
          _.each(self.fieldlist, (l) => {
            payload.customFields[l.name] = self.guess(l.name, self.headerList);
          });

          self.clearImportedItem(payload);
        };
        reader.readAsBinaryString(file);
      } catch (e) {
        // self.loader = false
        console.log(e);
      }
    },

    guess(what, where) {
      var match = _.find(where, (head) => {
        if (head && typeof head == "string") {
          return (
            head
              .toLowerCase()
              .trim()
              .indexOf(what.toLowerCase().trim()) > -1
          );
        } else {
          return false;
        }
      });
      if (!match) return "None";
      return match;
    },
    // Import contacts dialog
    async importContacts() {
      const self = this;
      // initiate loader
      self.loader = true;
      self.importing = true;
      // validate
      if (self.selectedlist == "") {
        self.loader = false;
        self.importing = false;
        self.snackbar = true;
        self.color = "#D18700";
        self.responseText = "Select a list before proceed";
        console.log("error ", self.responseText);
        return;
      }

      if (self.importedItem.phone == "") {
        self.loader = false;
        self.importing = false;
        self.snackbar = true;
        self.color = "#D18700";
        self.responseText = "Phone cannot be blank";
        console.log("error ", self.responseText);
        return;
      } else if (self.importedItem.phone == "None") {
        self.loader = false;
        self.importing = false;
        self.snackbar = true;
        self.color = "#D18700";
        self.responseText = "Phone selection should not be 'None'";
        console.log("error ", self.responseText);

        return;
      }

      if (self.addCountryCode) {
        var z = self.countryCode;
        if (!/^[\+0-9]+$/gm.test(z)) {
          self.loader = false;
          self.importing = false;
          self.snackbar = true;
          self.color = "#D18700";
          self.responseText = "Country code must be number";
          console.log("error ", self.responseText);
          return;
        }
      }

      if (self.selectListFromCSV) {
        if (self.importedItem.list == "") {
          self.loader = false;
          self.importing = false;
          self.snackbar = true;
          self.color = "#D18700";
          self.responseText = "Select list column before proceeding";
          return;
        } else if (self.importedItem.list == "None") {
          self.loader = false;
          self.importing = false;
          self.snackbar = true;
          self.color = "#D18700";
          self.responseText = "List selection should not be 'None'";
          console.log("error ", self.responseText);
          return;
        }
      }

      if (self.orderedImport && self.importedData.length > 5000) {
        self.loader = false;
        self.importing = false;
        self.snackbar = true;
        self.color = "#D18700";
        self.responseText =
          "You cannot upload more than 5000 contacts when importing in order. Please turn off ordered import from the advance settings";
        console.log("error ", self.responseText);
        return;
      }

      if (self.importedData.length > 5000) {
        self.loader = false;
        self.importing = false;
        self.snackbar = true;
        self.color = "#D18700";
        self.responseText =
          "This file contains more than 5000 rows, please split it into multiple files.";
        console.log("error ", self.responseText);
        return;
      }

      var payloadContact = [];
      var counter = 0;
      var filter = {
        where: {
          companyId: this.$store.getters.user.companyId,
        },
        include: {
          relation: "accounts",
          scope: {
            fields: ["username", "id"],
          },
        },
      };
      if (self.selectAssigneeFromCSV) {
        try {
          var response = await this.$http.get(
            `${self.$store.state.ENDPOINT}/lists?filter=${encodeURIComponent(
              JSON.stringify(filter)
            )}`
          );
          var listsAgents = response.body;

          self.loader = false;
          self.importing = false;
        } catch (err) {
          console.log("Error", err);

          self.loader = false;
          self.importing = false;

          self.$swal({
            type: "error",
            text: "Something went wrong. Please try again",
          });
        }
      }

      for (var x in self.importedData) {
        var payload = {
          called: false,
          smsSent: false,
          emailSent: false,
          linkSent: false,
          lock: false,
          companyId: self.$store.getters.user.companyId,
          accountsId: "",
          assignee: null,
          dispo: "",
          dnc: false,
          callbackDone: false,
          customFields: {},
          leadScore: 60,
          stageId: "",
        };
        try {
          //Phone number validations
          // black phone no field
          if (self.importedData[x][self.importedItem.phone] == "") {
            self.response[self.importedData[x][self.importedItem.name]] = {
              for: `Row ${x}`,
              success: false,
              msg: "Phone Number is not provided",
            };
            self.importedData[x].result = "Phone Number is not provided";
            continue;
          }
          // undefined phone number
          if (self.importedData[x][self.importedItem.phone] == undefined) {
            self.response[self.importedData[x][self.importedItem.name]] = {
              for: `Row ${x}`,
              success: false,
              msg: "Phone Number is not readable",
            };
            self.importedData[x].result = "Phone Number is not readable";
            continue;
          }

          var phoneNumber = validator.phoneNumberValidator(
            self.importedData[x][self.importedItem.phone]
          );

          if (phoneNumber.length < 5) {
            self.response[self.importedData[x][self.importedItem.name]] = {
              for: `Row ${x}`,
              success: false,
              msg: "Invalid Phone Number",
            };
            self.importedData[x].result = "Invalid Phone Number";
            continue;
          }

          // if country code is provided by the user
          if (self.addCountryCode) {
            payload.phone = self.countryCode + "" + phoneNumber;
          } else {
            payload.phone = phoneNumber;
          }

          //Secondary Phone number validations
          // undefined phone number
          if (
            self.importedItem.secondPhone == "None" ||
            self.importedItem.secondPhone == ""
          ) {
            payload.secondPhone = "";
          } else {
            payload.secondPhone =
              self.importedData[x][self.importedItem.secondPhone] == undefined
                ? ""
                : self.importedData[x][self.importedItem.secondPhone];

            var secondNumberValidate = validator.phoneNumberValidator(
              payload.secondPhone
            );
            // secondaty number assignment
            payload.secondPhone = secondNumberValidate;
          }

          // name field
          payload.name =
            self.importedData[x][self.importedItem.name] == undefined
              ? ""
              : self.importedData[x][self.importedItem.name].toString();
          if (
            self.importedItem.name == "None" ||
            self.importedItem.name == ""
          ) {
            payload.name = "";
          }

          // company validations
          payload.companyName =
            self.importedData[x][self.importedItem.companyName] == undefined
              ? ""
              : self.importedData[x][self.importedItem.companyName].toString();
          if (
            self.importedItem.companyName == "None" ||
            self.importedItem.companyName == ""
          ) {
            payload.companyName = "";
          }

          // email validations
          payload.email =
            self.importedData[x][self.importedItem.email] == undefined
              ? ""
              : self.importedData[x][self.importedItem.email].toString();
          if (
            self.importedItem.email == "None" ||
            self.importedItem.email == ""
          ) {
            payload.email = "";
          }

          // remarks
          payload.remarks =
            self.importedData[x][self.importedItem.remarks] == undefined
              ? ""
              : self.importedData[x][self.importedItem.remarks].toString();
          if (
            self.importedItem.remarks == "None" ||
            self.importedItem.remarks == ""
          ) {
            payload.remarks = "";
          }

          // address
          payload.address =
            self.importedData[x][self.importedItem.address] == undefined
              ? ""
              : self.importedData[x][self.importedItem.address].toString();
          if (
            self.importedItem.address == "None" ||
            self.importedItem.address == ""
          ) {
            payload.address = "";
          }

          // note
          payload.note =
            self.importedData[x][self.importedItem.note] == undefined
              ? ""
              : self.importedData[x][self.importedItem.note].toString();
          if (
            self.importedItem.note == "None" ||
            self.importedItem.note == ""
          ) {
            payload.note = "";
          }

          // extra field
          payload.extra =
            self.importedData[x][self.importedItem.extra] == undefined
              ? ""
              : self.importedData[x][self.importedItem.extra].toString();
          if (
            self.importedItem.extra == "None" ||
            self.importedItem.extra == ""
          ) {
            payload.extra = "";
          }

          var customFields = {};
          Object.entries(self.importedItem.customFields).forEach(
            ([key, value]) => {
              if (key.toString().toLowerCase() != "none") {
                customFields[key] = self.importedData[x][value];
              }
            }
          );

          payload.customFields = sanitizeCustomFields(
            customFields,
            self.fieldlist
          );

          if (this.selectListFromCSV) {
            // few validations for selected list from column
            if (self.importedData[x][self.importedItem.list] == undefined) {
              self.response[self.importedData[x][self.importedItem.name]] = {
                for: `Row ${x}`,
                success: false,
                msg: "List is not readable.",
              };
              self.importedData[x].result = "List is not readable.";
              continue;
            }

            var isListFound = _.find(self.lists, (l) => {
              return (
                l.name == self.importedData[x][self.importedItem.list].trim()
              );
            });

            if (isListFound && Object.keys(isListFound).length > 0) {
              payload.listId = isListFound.id;
            } else {
              self.response[self.importedData[x][self.importedItem.name]] = {
                for: `Row ${x}`,
                success: false,
                msg: "This list is not yet created.",
              };
              self.importedData[x].result = "This list is not yet created.";
              continue;
            }
          } else {
            payload.listId = self.selectedlist;
          }

          //assigment logic

          //from Sheet or csv
          if (self.selectAssigneeFromCSV) {
            if (self.importedData[x][self.importedItem.assignee] == undefined) {
              self.response[self.importedData[x][self.importedItem.name]] = {
                for: `Row ${x}`,
                success: false,
                msg: "Assignee is not readable.",
              };
              self.importedData[x].result = "Assignee is not readable.";
              continue;
            }

            var tempAssigneeObject = _.find(
              _.find(listsAgents, (l) => {
                return l.id == payload.listId;
              }).accounts,
              (a) => {
                return (
                  a.username.toLowerCase() ==
                  self.importedData[x][self.importedItem.assignee]
                    .trim()
                    .toLowerCase()
                );
              }
            );

            // console.log("tempAssigneeObject", tempAssigneeObject);

            if (tempAssigneeObject) {
              payload.assignee = tempAssigneeObject.id;
            } else {
              self.response[self.importedData[x][self.importedItem.name]] = {
                for: `Row ${x}`,
                success: false,
                msg: "List is not assigned to member.",
              };
              self.importedData[x].result = "List is not assigned to member.";
              continue;
            }
          } else {
            if (
              this.addAgent === "addAgentList" &&
              this.selectAssignedUser.length > 0
            ) {
              payload.assignee = this.selectAssignedUser[counter].id;
              counter++;
              if (counter >= this.selectAssignedUser.length) counter = 0;
            }
          }

          // console.log("payload" + x + ": ", payload);

          self.importedData[x].result = "successful";
          payloadContact.push(payload);
        } catch (e) {
          self.loader = false;
          self.importing = false;
          console.log("erorr from  the loop in bulk", e);
        }
      }
      if (payloadContact.length < 1) {
        if (Object.keys(self.response).length > 0) {
          self.step = 2;
          self.$swal({
            type: "error",
            text:
              "Please map right each columns from your spreadsheeet to the GoDial's fields",
          });
        }
        return;
      }
      this.payloadContact = payloadContact;
      // console.log("payloadContact: ", payloadContact);
      self.$http
        .post(`${self.$store.state.ENDPOINT}/contacts/bulk`, {
          contacts: payloadContact,
          orderedImport: self.orderedImport,
        })
        .then((response) => {
          var errors = response.body.errors;
          self.numberOfItems = response.body.success;
          for (var index = self.importedData.length - 1; index >= 0; index--) {
            var errPresent = _.indexOf(
              errors,
              validator.phoneNumberValidator(
                self.importedData[index][self.importedItem.phone]
              )
            );

            if (errPresent >= 0) {
              errors.splice(errPresent, 1);
              self.response[index] = {
                for: `Row ${index}`,
                success: false,
                msg: "Duplicate Phone no cannot be added",
              };
              self.importedData[index].result =
                "Duplicate Phone no cannot be added";
            }
          }
          self.contactImportResults = [...self.importedData];
          for (let i in self.contactImportResults) {
            // console.log(
            //   "self.contactImportResults[i]",
            //   self.contactImportResults[i].result
            // );

            if (
              typeof self.contactImportResults[i] != "undefined" &&
              typeof self.contactImportResults[i].result != "undefined"
            ) {
              if (typeof self.payloadContact[i] != "undefined") {
                self.payloadContact[i]["result"] =
                  self.contactImportResults[i].result == "successful"
                    ? "Successfully Imported"
                    : "Failed to Import";
              }
            }
          }
          //loader
          self.loader = false;
          self.importing = false;
          // dialogs
          self.step = 3;
          self.clearImportedItem();

          //reset all the variable
          self.pasteCSV = false;
          self.importedData = [];
          self.fileInput = false;
          self.csvPastedData = "";
          self.selectListFromCSV = false;
          self.addCountryCode = false;
          self.countryCode = "";
          self.fileName = "";

          self.refresh();
          fb.log("bulk_list");
        })
        .catch((err) => {
          self.loader = false;
          self.importing = false;
          console.log("error in Import: ", err);
          // self.close();
        });
    },

    //Paste
    paste() {
      const self = this;
      self.fileInput = true;
      self.headerList = []; // init headerList as empty array
      self.importedData = []; // init the data as empty array

      var data = self.csvPastedData;
      data = Papa.parse(data, {
        header: true,
        skipEmptyLines: true,
      });

      // define headerList
      self.headerList = data.meta.fields;
      self.headerList = _.filter(self.headerList, (d) => {
        if (d != "") return d;
      });

      for (var y in data.data) {
        var payload = {};

        if (y == 0) {
          continue;
        }

        for (var x in self.headerList) {
          payload[self.headerList[x]] = data.data[y][self.headerList[x]];
        }
        self.importedData.push(payload);
      }
    },

    // close all module
    close() {
      this.clearEditedItem();
      this.clearImportedItem();

      //loader
      this.loader = false;
      this.step = 1;
      (this.sheetId = ""),
        (this.sheetName = ""),
        // dialogs
        (this.dialog = false);
      this.importDialog = false;
    },

    downloadResults() {
      const self = this;
      // console.log("contactImportResults: ", self.contactImportResults);
      // console.log("importedData: ", self.importedData);
      try {
        var csv = Papa.unparse(self.contactImportResults);
        var csvData = new Blob([csv], {
          type: "text/csv;charset=utf-8;",
        });
        var csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, "report.csv");
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "report.csv");
        tempLink.click();
        self.contactImportResults = [];
      } catch (e) {
        console.log("error creating results", e);
        self.contactImportResults = [];
      }
    },

    refresh() {
      this.$emit("refreshData");
    },
    clearDialog() {
      this.fileName = "";
      this.fileInput = false;
    },
    uploadCsv() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      var temp = e.target.files[0];
      this.upload(temp);
    },
    resetFileChanged() {
      this.$refs.uploader.value = "";
    },
    onDrop(e) {
      this.dragover = false;
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        this.$store.dispatch("addNotification", {
          message: "Only one file can be uploaded at a time..",
          colour: "error",
        });
      } else {
        this.upload(e.dataTransfer.files[0]);
      }
    },
    selectAllUsers() {
      if (this.addAgent === "addAgentList") {
        this.selectAssignedUser = this.selectUsersForAssignment;
      } else {
        this.selectAssignedUser = [];
      }
    },
  },
};
</script>

<style>
.scroller {
  overflow-y: auto;
}
.droppercard {
  border: lightgray dashed;
  max-width: 60%;
  margin: auto !important;
}

.tooltip .tooltip-inner {
  max-width: 250px !important;
  width: 200px !important;
}
</style>
